import React from 'react'
import { Link } from 'gatsby'
import { Highlight, Snippet } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'

const categories = [
  {
    category: 'よくある質問',
    class: 'Qa',
    label: 'よくある質問',
  },
  {
    category: 'ユーザー向け機能説明',
    class: 'User',
    label: 'ユーザー向け',
  },
  {
    category: '管理者向け機能説明',
    class: 'Admin',
    label: '管理者向け',
  },
]

const PageHit = ({ hit }) => {
  const category = categories.find((item) => item.category === hit.category)
  return (
    <div className="search_container">
      <h3 className="search_result_ttl">
        <Link to={hit.slug} className="search_result_ttlLnk">
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </Link>
      </h3>
      <ul className="search_result_ullst">
        {category ? (
          <li className={`icon${category.class}_s search_result_lst`}>
            {category.label}
          </li>
        ) : (
          <li className="search_result_lst">{hit.category}</li>
        )}
      </ul>
      <p>
        <Snippet attribute="excerpt" hit={hit} tagName="mark" />
      </p>
    </div>
  )
}

PageHit.propTypes = {
  hit: PropTypes.object,
}

export default PageHit
