import React from 'react'
import { connectPagination } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'

const Pagination = ({ currentRefinement, nbPages, refine, createURL }) => {
  if (nbPages === 0) return null
  return (
    <ul className="pagination">
      {currentRefinement !== 1 ? (
        <li>
          <a
            href={createURL(1)}
            onClick={(event) => {
              event.preventDefault()
              refine(1)
            }}
            className="pagelnk"
          >
            «
          </a>
        </li>
      ) : null}
      {new Array(nbPages).fill(null).map((_, index) => {
        const page = index + 1
        return (
          <li key={index}>
            {currentRefinement === page ? (
              <span className="current">{page}</span>
            ) : (
              <a
                href={createURL(page)}
                onClick={(event) => {
                  event.preventDefault()
                  refine(page)
                }}
                className="pagelnk"
              >
                {page}
              </a>
            )}
          </li>
        )
      })}
      {currentRefinement !== nbPages ? (
        <li>
          <a
            href={createURL(nbPages)}
            onClick={(event) => {
              event.preventDefault()
              refine(nbPages)
            }}
            className="pagelnk"
          >
            »
          </a>
        </li>
      ) : null}
    </ul>
  )
}

Pagination.propTypes = {
  currentRefinement: PropTypes.number,
  nbPages: PropTypes.number,
  refine: PropTypes.func,
  createURL: PropTypes.func,
}

export default connectPagination(Pagination)
