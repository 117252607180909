import React from 'react'
import { Hits, Index } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'
import HitCount from '@/components/search/hitCount'
import PageHit from '@/components/search/pageHit'

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
)

HitsInIndex.propTypes = {
  index: PropTypes.object,
}

export default HitsInIndex
