import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'

const HitCount = connectStateResults(({ searchResults }) => {
  if (!searchResults) return null
  const { query, nbHits, page, hitsPerPage, hits } = searchResults
  const message = (() => {
    if (nbHits === 0) return `“${query || ''}”の検索結果(0件)`
    const start = page * hitsPerPage
    return `“${query || ''}”の検索結果(${nbHits}件中${start + 1} - ${
      start + hits.length
    }件を表示)`
  })()

  return <h2>{message}</h2>
})

HitCount.propTypes = {
  searchResults: PropTypes.object,
}

export default HitCount
