import React from 'react'
import { PoweredBy } from 'react-instantsearch-dom'
import PropTypes from 'prop-types'
import HitsInIndex from '@/components/search/hitsInIndex'
import Pagination from '@/components/search/pagination'

const SearchResult = ({ className }) => (
  <div className={className}>
    <HitsInIndex index={{ name: process.env.GATSBY_ALGOLIA_INDEX_NAME }} />
    <Pagination />
    <PoweredBy />
  </div>
)

SearchResult.propTypes = {
  indices: PropTypes.arrayOf({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default SearchResult
