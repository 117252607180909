import React, { useMemo } from 'react'
import Layout from '@/components/layout'
import Seo from '@/components/seo'
import queryString from 'query-string'
import algoliaSearch from 'algoliasearch/lite'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import SearchResult from '@/components/search/searchResult'
import PropTypes from 'prop-types'
import Faq from '@/components/faq'
import Contact from '@/components/contact'
import ScrollToTopButton from '@/components/scrollToTopButton'
import PageTitle from '@/components/pageTitle'
import Breadcrumb from '@/components/breadcrumb'
import '@/styles/search.scss'
import ArticleListWidget from '@/components/articleListWidget'

const Search = ({ location }) => {
  const { query } = queryString.parse(location.search)

  const algoliaClient = useMemo(
    () =>
      algoliaSearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      if (requests.every(({ params }) => !params?.query)) {
        return Promise.resolve({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })
      }
      return algoliaClient.search(requests)
    },
  }

  return (
    <Layout search={true}>
      <Seo />
      <Breadcrumb items={[{ title: '検索結果' }]} />
      <PageTitle title="検索結果" />
      <div className="wraper main_contents">
        <main>
          <article>
            <section>
              <InstantSearch
                indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
                searchClient={searchClient}
              >
                <Configure query={query || ''} hitsPerPage={20} distinct />
                <SearchResult />
              </InstantSearch>
            </section>
          </article>
        </main>
        <aside className="category">
          <ArticleListWidget
            className="bl_guideUser"
            category="ユーザー向け機能説明"
            title="ユーザー向け機能説明"
          />

          <ArticleListWidget
            className="bl_guideAdmin"
            category="管理者向け機能説明"
            title="管理者向け機能説明"
          />
        </aside>
      </div>
      <Faq />
      <Contact />
      <ScrollToTopButton />
    </Layout>
  )
}
Search.propTypes = {
  location: PropTypes.object,
}
export default Search
